<template>
  <v-app id="inspire">
    <v-container>
      <div class="container">
        <div class="forms-container">
          <div class="signin-signup">
            <v-form
              id="login-form"
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <h2 class="title-login">
                Sign in
              </h2>
              <v-text-field
                id="username"
                v-model="loginForm.username"
                :rules="usernameRules"
                label="Email/Username"
                name="username"
                append-icon="mdi-account"
                class="input-field"
                type="text"
                required
                solo
              />
              <v-text-field
                v-model="loginForm.password"
                :rules="passwordRules"
                :append-icon="typePassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="typePassword ? 'password' : 'text'"
                class="input-field"
                label="Password"
                name="password"
                solo
                @click:append="() => (typePassword = !typePassword)"
                @keyup.enter.native="handleLogin"
              />
              <v-btn
                color="#16A75C"
                class="btn solid"
                :loading="isLoading"
                @click="handleLogin"
              >
                Login
              </v-btn>
            </v-form>
          </div>
        </div>
        <div class="panels-container">
          <div class="panel left-panel">
            <div class="content">
              <h3>digiteam</h3>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Debitis,
                ex ratione. Aliquid!
              </p>
            </div>
            <img
              src="@/assets/log-min.png"
              class="image"
              alt=""
            >
          </div>
        </div>
      </div>
    </v-container>
  </v-app>
</template>

<script>
  import Vue from 'vue'
  export default {
    name: 'LoginPage',
    props: {
      source: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        valid: true,
        typePassword: String,
        isLoading: false,
        loginForm: {
          username: '',
          password: '',
        },
        usernameRules: [
          v => !!v || 'Username harus diisi',
        ],
        passwordRules: [
          v => !!v || 'Password harus diisi',
          v => (v && v.length >= 4) || 'Password harus lebih dari 4 karakter',
        ],
      }
    },
    methods: {
      handleLogin () {
        if (this.$refs.form.validate()) {
          this.isLoading = true
          this.$store.dispatch('user/login', this.loginForm)
            .then(async (response) => {
              await this.$router.push({ path: '/' })
              this.isLoading = false
            })
            .catch((e) => {
              this.isLoading = false
              // this.$refs.form.reset()
            })
        }
      },
      signInGoogle: function () {
        Vue.googleAuth().signIn(this.onSignInSuccess, this.onSignInError)
      },
      onSignInSuccess: function (authorizationCode) {
        const data = {
          access_token: authorizationCode,
        }
        this.$store.dispatch('user/loginSocialOauth', data).then(() => {
          this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
          this.isLoading = false
        }).catch(() => {
          this.$store.dispatch('toast/errorToast', 'Login dengan google gagal')
          this.isLoading = false
        })
      },
      onSignInError: function () {
        this.$store.dispatch('toast/errorToast', 'Login dengan google gagal')
      },
    },
  }
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  position: relative;
  width: 100%;
  background-color: #fff;
  min-height: 100vh;
  overflow: hidden;
}
.forms-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.signin-signup {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 75%;
  width: 50%;
  transition: 1s 0.7s ease-in-out;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
}

form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0rem 5rem;
  transition: all 0.2s 0.7s;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

form.sign-up-form {
  opacity: 0;
  z-index: 1;
}

form.sign-in-form {
  z-index: 2;
}

.title-login {
  font-size: 2.2rem;
  color: #444;
  margin-bottom: 10px;
}

.input-field {
  max-width: 380px;
  width: 100%;
  margin: 10px 0;
  height: 55px;
  border-radius: 55px;
  padding: 0 0.4rem;
  position: relative;
}

.btn {
  width: 150px;
  background-color: #399F4F;
  border: none;
  outline: none;
  height: 49px;
  border-radius: 49px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
}

.btn:hover {
  background-color: #399F4F;
}
.panels-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.container:before {
  content: "";
  position: absolute;
  height: 1500px;
  width: 100%;
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  background-image: linear-gradient(-45deg, #16A75C 0%, #42A5F5 100%);
  transition: 1.8s ease-in-out;
  border-radius: 50%;
  z-index: 6;
}

.image {
  width: 100%;
  transition: transform 1.1s ease-in-out;
  transition-delay: 0.4s;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  text-align: center;
  z-index: 6;
}

.left-panel {
  pointer-events: all;
  padding: 3rem 17% 2rem 12%;
}

.right-panel {
  pointer-events: none;
  padding: 3rem 12% 2rem 17%;
}

.panel .content {
  color: #fff;
  transition: transform 0.9s ease-in-out;
  transition-delay: 0.6s;
}

.panel h3 {
  font-weight: 600;
  line-height: 1;
  font-size: 1.5rem;
}

.panel p {
  font-size: 0.95rem;
  padding: 0.7rem 0;
}

.btn.transparent {
  margin: 0;
  background: none;
  border: 2px solid #fff;
  width: 130px;
  height: 41px;
  font-weight: 600;
  font-size: 0.8rem;
}

.right-panel .image,
.right-panel .content {
  transform: translateX(800px);
}

/* ANIMATION */

.container.sign-up-mode:before {
  transform: translate(100%, -50%);
  right: 52%;
}

.container.sign-up-mode .left-panel .image,
.container.sign-up-mode .left-panel .content {
  transform: translateX(-800px);
}

.container.sign-up-mode .signin-signup {
  left: 25%;
}

.container.sign-up-mode form.sign-up-form {
  opacity: 1;
  z-index: 2;
}

.container.sign-up-mode form.sign-in-form {
  opacity: 0;
  z-index: 1;
}

.container.sign-up-mode .right-panel .image,
.container.sign-up-mode .right-panel .content {
  transform: translateX(0%);
}

.container.sign-up-mode .left-panel {
  pointer-events: none;
}

.container.sign-up-mode .right-panel {
  pointer-events: all;
}

@media (max-width: 870px) {
  .container {
    min-height: 600px;
    height: 100vh;
  }
  .signin-signup {
    width: 100%;
    top: 95%;
    transform: translate(-50%, -100%);
    transition: 1s 0.8s ease-in-out;
  }

  .signin-signup,
  .container.sign-up-mode .signin-signup {
    left: 50%;
  }

  .panels-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }

  .panel {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 8%;
    grid-column: 1 / 2;
  }

  .right-panel {
    grid-row: 3 / 4;
  }

  .left-panel {
    grid-row: 1 / 2;
  }

  .image {
    width: 200px;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.6s;
  }

  .panel .content {
    padding-right: 15%;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.8s;
  }

  .panel h3 {
    font-size: 1.2rem;
  }

  .panel p {
    font-size: 0.7rem;
    padding: 0.5rem 0;
  }

  .btn.transparent {
    width: 110px;
    height: 35px;
    font-size: 0.7rem;
  }

  .container:before {
    width: 1500px;
    height: 1500px;
    transform: translateX(-50%);
    left: 30%;
    bottom: 68%;
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  }

  .container.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 32%;
    right: initial;
  }

  .container.sign-up-mode .left-panel .image,
  .container.sign-up-mode .left-panel .content {
    transform: translateY(-300px);
  }

  .container.sign-up-mode .right-panel .image,
  .container.sign-up-mode .right-panel .content {
    transform: translateY(0px);
  }

  .right-panel .image,
  .right-panel .content {
    transform: translateY(300px);
  }

  .container.sign-up-mode .signin-signup {
    top: 5%;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 570px) {
  form {
    padding: 0 1.5rem;
  }

  .image {
    display: none;
  }
  .panel .content {
    padding: 0.5rem 1rem;
  }
  .container {
    padding: 1.5rem;
  }

  .container:before {
    bottom: 72%;
    left: 50%;
  }

  .container.sign-up-mode:before {
    bottom: 28%;
    left: 50%;
  }
}
</style>
